import React from 'react'

import { Props } from './protocols'

export const NoneSvg: React.FC<Props> = (props: Props) => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M22.548 9 23 7h-5.364L19 1h-2l-1.364 6h-5L12 1h-2L8.636 7H2.452L2 9h6.182l-1.364 6h-5.36L1 17h5.364L5 23h2l1.364-6h5L12 23h2l1.364-6h6.185L22 15h-6.182l1.364-6h5.366zm-8.73 6h-5l1.364-6h5l-1.364 6z" />
    </svg>
  )
}
