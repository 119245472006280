import { FieldHookConfig, useField } from 'formik'
import { useMemo } from 'react'
import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

type InputGroupProps = FieldHookConfig<string | number>

const TextareaUI = styled('textarea', {
  ...tw`text-gray-500 border border-gray-300 rounded-lg placeholder-gray-500 p-1.5 px-3 outline-none w-full`,
  variants: {
    error: {
      true: tw`border-red-400`
    }
  }
})

export const Textarea: React.FC<InputGroupProps> = ({ ...props }) => {
  const [field, meta] = useField(props)

  const isInvalid = useMemo<boolean>(() => {
    return !!meta.error && !!meta.touched
  }, [meta.error, meta.touched])

  return <TextareaUI {...field} error={isInvalid} />
}
