import React, { createContext, useState } from 'react'

import {
  TopbarContextData,
  TopbarProviderProps,
  TopbarState
} from './protocols'

export const TopbarContext = createContext<TopbarContextData>(
  {} as TopbarContextData
)

export const TopbarProvider: React.FC<TopbarProviderProps> = ({
  children
}: TopbarProviderProps) => {
  const [content, setContent] = useState<React.ReactNode>(<></>)

  const updateTopbar = ({ content }: TopbarState): void => {
    setContent(content)
  }

  return (
    <TopbarContext.Provider value={{ updateTopbar, content }}>
      {children}
    </TopbarContext.Provider>
  )
}
