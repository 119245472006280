import {
  NavigationMenu as NavigationMenuUI,
  Sidebar
} from '@brmediagroup/mis-ui-library'
import React, { useMemo } from 'react'

import { NAVIGATION_ITEMS, shouldDisplayTiktokAuthorization } from './constants'
import { usePresenter } from './presenter'
import { NavigationMenuProps } from './protocols'

export const NavigationMenu: React.FC<NavigationMenuProps> = ({
  pathname,
  onSelect
}) => {
  const { activeItem, handleSelectItem } = usePresenter({
    pathname,
    onSelect
  })
  const navigationItems = useMemo(() => {
    let items = Object.values(NAVIGATION_ITEMS)
    if (!shouldDisplayTiktokAuthorization()) {
      items = items.filter(({ name }) => name !== 'tiktok')
    }
    return items
  }, [])

  return (
    <div>
      <Sidebar.Title>Menu</Sidebar.Title>
      <NavigationMenuUI>
        {navigationItems.map((item) => (
          <NavigationMenuUI.Link
            key={item.name}
            active={activeItem === item.name}
            onSelect={() => handleSelectItem(item.name)}
          >
            {item.icon}
            {item.label}
          </NavigationMenuUI.Link>
        ))}
      </NavigationMenuUI>
    </div>
  )
}
