import { Logos, Sidebar as SidebarUI } from '@brmediagroup/mis-ui-library'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React from 'react'

import { BrandDropdownMenu } from '@/presentation/components/contexts/sidebar/brand-dropdown-menu'
import { NavigationMenu } from '@/presentation/components/structures'

import { usePresenter } from './presenter'
import { SidebarComponent, SidebarProps } from './protocols'

export const Sidebar: SidebarComponent<SidebarProps> = () => {
  const { handleOnLogoClick, handleOnNavigate } = usePresenter()
  const router = useRouter()

  const { data: session } = useSession()

  return (
    <div>
      <SidebarUI
        logo={
          <button onClick={handleOnLogoClick}>
            <Logos.Mis />
          </button>
        }
      >
        <NavigationMenu
          pathname={router.pathname}
          onSelect={handleOnNavigate}
        />
        {session && (
          <BrandDropdownMenu
            brand={session.user.name!}
            email={session.user.email!}
            iconUrl={session.user.image!}
          />
        )}
      </SidebarUI>
    </div>
  )
}

Sidebar.Context = SidebarUI.Context
Sidebar.Provider = SidebarUI.Provider
Sidebar.Trigger = SidebarUI.Trigger
Sidebar.Divider = SidebarUI.Divider
