import { useRouter } from 'next/router'

export const usePresenter = () => {
  const router = useRouter()

  const handleOnLogoClick = () => router.replace('/app/campaigns')

  const handleOnNavigate = (value: string) => {
    return router.replace(`/app/${value}`)
  }

  return {
    handleOnLogoClick,
    handleOnNavigate
  }
}
