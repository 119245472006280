import { useField, FieldHookConfig } from 'formik'
import { useMemo } from 'react'
import InputMask from 'react-input-mask'

import { Feedback } from './feedback'
import { Input } from './input'
import { Label } from './label'

type InputGroupProps = {
  label?: string
  mask?: string
  containerClassName?: string
} & FieldHookConfig<string | number>

export const InputGroup: React.FC<InputGroupProps> = ({
  label,
  mask,
  containerClassName,
  ...props
}) => {
  const [field, meta] = useField(props)

  const isInvalid = useMemo<boolean>(() => {
    return !!meta.error && !!meta.touched
  }, [meta.error, meta.touched])

  return (
    <div className={`flex flex-col ${containerClassName}`}>
      {!!label && <Label>{label}</Label>}
      {!!mask ? (
        <InputMask
          mask={mask ?? ''}
          maskPlaceholder={null}
          value={field.value}
          onChange={field.onChange}
        >
          <Input {...props} error={isInvalid} />
        </InputMask>
      ) : (
        <Input {...field} {...props} error={isInvalid} />
      )}
      {isInvalid && <Feedback>{meta.error}</Feedback>}
    </div>
  )
}
