import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

export const ModalContent = styled('div', {
  ...tw`bg-white shadow-lg rounded-lg z-50`,
  variants: {
    size: {
      small: tw`w-[90vw] md:w-[400px]`,
      medium: tw`w-[90vw] md:w-[600px]`,
      large: tw`w-[90vw] md:w-[900px]`
    }
  }
})

ModalContent.defaultProps = {
  size: 'medium'
}
