import React from 'react'
import Jazzicon from 'react-jazzicon'

type Props = {
  userId: string
  size: number
}

export const UserIcon: React.FC<Props> = (props) => {
  return (
    <Jazzicon
      diameter={props.size}
      tw="data-icon rounded-full"
      seed={parseInt(props.userId, 16)}
    />
  )
}
