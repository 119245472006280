import * as RadixDialog from '@radix-ui/react-dialog'
import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

export const Overlay = styled(RadixDialog.Overlay, {
  ...tw`bg-black/70 fixed transition-opacity grid z-50
        top-0 bottom-0 left-0 right-0 overflow-y-auto place-items-center`
})

export const Content = styled(RadixDialog.Content, {
  ...tw`w-[80ch] relative
        rounded-[6px] bg-white p-6
        focus:outline-none`
})

export const MediaContent = styled(RadixDialog.Content, {
  ...tw`w-fit flex relative
        rounded-[6px] bg-white py-9 px-6
        focus:outline-none`
})
