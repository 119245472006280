import { Sidebar, Topbar as TopbarUI } from '@brmediagroup/mis-ui-library'
import { useContext } from 'react'

import { TopbarContext } from '@/presentation/components/contexts/topbar/topbar-context'

export type TopbarProps = {
  children?: React.ReactElement | React.ReactNode
  extra?: React.ReactElement | React.ReactNode
}

export const Topbar: React.FC<TopbarProps> = ({ extra }) => {
  const { content } = useContext(TopbarContext)

  return (
    <TopbarUI>
      <TopbarUI.Container>
        <TopbarUI.Trigger>
          <Sidebar.Trigger color="light" size="sm" />
        </TopbarUI.Trigger>
        <TopbarUI.Content>{content}</TopbarUI.Content>
      </TopbarUI.Container>
    </TopbarUI>
  )
}
