import { Logos } from '@brmediagroup/mis-ui-library'
import Image from 'next/image'
import React from 'react'

type AuthLayoutProps = {
  children: React.ReactNode
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-screen">
      <div className="flex flex-col items-center justify-center max-md:w-full w-1/3 px-8">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <Logos.Mis fill="#792cc8" />
          </div>

          <div className="mt-8">
            <div>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 md:block">
        <Image
          className="absolute inset-0 h-full w-auto object-cover"
          src="https://creators-lab.mis-app.com/content/images/size/w2000/2023/02/menina-asiatica-pressionando-tecnologia-futurista-de-tela-digital.jpg"
          alt="menina asiática pressionando tecnologia futurista de tela digital"
          objectFit="cover"
          layout="fill"
        />
      </div>
    </div>
  )
}
