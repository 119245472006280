import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

export const Input = styled('input', {
  ...tw`text-gray-500 text-sm md:text-base leading-normal border border-gray-300
    focus:(ring-1 ring-purple-300 border-purple-600 text-blue-800)
    disabled:(outline-none ring-0 border-gray-200 text-gray-500 placeholder-gray-500)
    rounded-lg placeholder-gray-500 p-1.5 px-3 outline-none`,
  variants: {
    error: {
      true: tw`border-red-400 focus:(ring-1 ring-red-50 border-red-400)!`
    }
  }
})
