import * as RadixDialog from '@radix-ui/react-dialog'
import React, { useState } from 'react'

import { Icon } from '../icon/Icon'

import { Content, Overlay } from './styles'

type ScrollableDialogProps = {
  content: React.ReactNode
  title: string
  trigger: React.ReactNode
}

export const ScrollableDialog: React.FC<ScrollableDialogProps> = ({
  trigger,
  title,
  content
}) => {
  const [open, setOpen] = useState(false)

  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen}>
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <RadixDialog.Portal>
        <Overlay>
          <Content>
            <RadixDialog.Title className="mb-4 text-lg">
              {title}
            </RadixDialog.Title>
            <div>{content}</div>
            <RadixDialog.Close asChild>
              <button aria-label="Close">
                <Icon
                  type="outlined"
                  name="XIcon"
                  className="text-black absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
                />
              </button>
            </RadixDialog.Close>
          </Content>
        </Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}
