import { Icon } from '../icon/Icon'

export type NotificationProps = {
  status: 'error' | 'success'
  show: boolean
  title: string
  message: string
}

type IconByStatusProps = Record<
  NotificationProps['status'],
  React.ComponentProps<typeof Icon>
>

const ICON_PROPS_BY_STATUS = {
  error: {
    type: 'outlined',
    name: 'XCircleIcon',
    className: 'text-red-400'
  },
  success: {
    type: 'outlined',
    name: 'CheckCircleIcon',
    className: 'text-green-300'
  }
} as IconByStatusProps

export const Notification: React.FC<NotificationProps> = ({
  show,
  title,
  message,
  status
}) => {
  if (!show) return null

  return (
    <div className="fixed top-0 right-0 bg-purple-600 rounded-xl p-3 m-5 shadow-lg max-w-xs w-full">
      <div className="flex items-center gap-3">
        <Icon {...ICON_PROPS_BY_STATUS[status]} />
        <div>
          <h5 className="text-white font-bold text-sm">{title}</h5>
          <p className="text-gray-200 text-xs">{message}</p>
        </div>
      </div>
    </div>
  )
}
