import React from 'react'

import { Providers } from '@/presentation/protocols'

import { InstagramSvg } from './instagram-svg'
import { NoneSvg } from './none-svg'
import { Props } from './protocols'
import { TikTokSvg } from './tiktok-svg'
import { TwitterSvg } from './twitter-svg'

export const SVG_ICONS: Record<Providers, React.FC<Props>> = {
  instagram: InstagramSvg,
  tiktok: TikTokSvg,
  twitter: TwitterSvg,
  none: NoneSvg
}
