import Head from 'next/head'

import { TopbarProvider } from '@/presentation/components/contexts/topbar/topbar-context'
import { Sidebar, Topbar } from '@/presentation/components/structures'

type AppLayoutProps = {
  children: React.ReactElement | React.ReactNode
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Sidebar.Provider>
      <Head>
        <title>MIS | Marcas</title>
      </Head>
      <TopbarProvider>
        <div className="grid grid-cols-[240px_1fr] max-lg:grid-cols-[0px_1fr] ">
          <div className="h-full">
            <div className="fixed top-0 left-0 bottom-0 z-50">
              <Sidebar />
            </div>
          </div>
          <main className="bg-slate-50 min-h-screen w-full z-0">
            <div className="fixed top-0 w-full z-10">
              <Topbar />
            </div>
            <section className="max-md:px-4 md:px-16 p-16 sm:p-4 w-full mt-8">
              {children}
            </section>
          </main>
        </div>
      </TopbarProvider>
    </Sidebar.Provider>
  )
}
