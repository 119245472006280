import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

export const ModalWrapper = styled('div', {
  ...tw`z-40 w-full h-full transition-all
  ease-in-out duration-500 flex items-center
  fixed justify-center bg-black/80 top-0
  left-0`
})
