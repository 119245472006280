import * as OutlinedIcons from '@heroicons/react/outline'
import * as SolidIcons from '@heroicons/react/solid'
import { Twitter, Instagram, Tiktok } from '@icons-pack/react-simple-icons'
import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

const SimpleIcons = {
  Twitter,
  Instagram,
  Tiktok
}

const ICON_TYPES = {
  solid: SolidIcons,
  outlined: OutlinedIcons,
  simple: SimpleIcons
}

type SolidKeys = keyof typeof ICON_TYPES.solid
type OutlinedKeys = keyof typeof ICON_TYPES.outlined
type SimpleKeys = keyof typeof ICON_TYPES.simple

type IconTypes =
  | { type: 'solid'; name: SolidKeys }
  | { type: 'outlined'; name: OutlinedKeys }
  | { type: 'simple'; name: SimpleKeys }

export type UIIconProps = {
  type: string
  className?: string
} & IconTypes

const UIIcon: React.FC<UIIconProps> = ({ type, name, className = '' }) => {
  let Icon

  switch (type) {
    case 'outlined':
      Icon = ICON_TYPES['outlined'][name]
      return <Icon className={className} />
    case 'solid':
      Icon = ICON_TYPES['solid'][name]
      return <Icon className={className} />
    case 'simple':
      Icon = ICON_TYPES['simple'][name]
      return <Icon className={className} />
    default:
      return <></>
  }
}

export const Icon = styled(UIIcon, {
  variants: {
    size: {
      xs: tw`w-4 h-4`,
      sm: tw`w-5 h-5`,
      md: tw`w-6 h-6`,
      lg: tw`w-7 h-7`,
      xl: tw`w-10 h-10`,
      xxl: tw`w-12 h-12`,
      '2xl': tw`w-24 h-24`
    }
  }
})

Icon.defaultProps = {
  size: 'md'
}
