import Image from 'next/image'
import { useMemo } from 'react'

import { Media } from '@/common/media'

type ImageGroupProps = {
  images?: (Media | null)[] | null
  limit?: number
}

export const ImageGroup: React.FC<ImageGroupProps> = ({
  images,
  limit = 3
}) => {
  const { limitedImages, collapsedImages } = useMemo(() => {
    if (images && images.length) {
      const exceedsLimit = images.length > limit
      const limitedImages = exceedsLimit ? images.slice(0, limit) : images
      const collapsedImages = exceedsLimit ? images.length - limit : 0

      return {
        limitedImages,
        collapsedImages
      }
    }

    return {
      limitedImages: [],
      collapsedImages: 0
    }
  }, [images, limit])

  if (images) {
    return (
      <>
        {limitedImages?.map((media) => (
          <div
            key={media?.url}
            className="h-10 w-10 relative md:h-16 md:w-16 rounded-md outline outline-white z-10"
          >
            <Image
              src={media?.isVideo ? '/sample.png' : media?.url ?? ''}
              layout="fill"
              objectFit="cover"
              alt={media?.url}
              className="rounded-md"
              priority
            />
          </div>
        ))}
        {collapsedImages > 0 && (
          <div className="h-16 w-16 flex text-gray-500 font-bold items-center justify-center bg-gray-200 rounded-md outline outline-white z-10">
            +{collapsedImages}
          </div>
        )}
      </>
    )
  }

  return null
}
