import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'

export const usePresenter = () => {
  const router = useRouter()

  const handleGoToSettings = () => router.push('/app/settings')

  const handleSignOut = () => {
    signOut({ redirect: false })
    router.replace('/')
  }

  return { handleSignOut, handleGoToSettings }
}
