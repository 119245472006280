import React from 'react'

import { Icon } from '../../ui'

export type NavigationItemProp = {
  name: string
  label: string
  icon: React.ReactElement
}

export const shouldDisplayTiktokAuthorization = () =>
  process.env.NEXT_PUBLIC_FLAG_TIKTOK_AUTHENTICATION === 'true'

export const NAVIGATION_ITEMS: NavigationItemProp[] = [
  {
    name: 'campaigns',
    label: 'Suas campanhas',
    icon: <Icon type="outlined" name="ChartBarIcon" className="h-4 w-4" />
  },
  {
    name: 'tiktok',
    label: 'TikTok business',
    icon: <Icon type="simple" name="Tiktok" className="h-4 w-4" />
  }
]
