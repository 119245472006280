import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

export const Button = styled('button', {
  ...tw` w-full font-bold rounded-lg`,
  variants: {
    size: {
      sm: tw`text-sm px-4 py-1.5`,
      md: tw`text-base px-6 py-2`
    },
    colorType: {
      purple: tw`bg-purple-600 text-white disabled:(bg-purple-300 text-purple-50)`
    },
    outlined: {
      true: tw``
    }
  },
  compoundVariants: [
    {
      colorType: 'purple',
      outlined: true,
      css: tw`bg-transparent border border-purple-600 text-purple-600`
    }
  ]
})

Button.defaultProps = {
  size: 'md',
  colorType: 'purple',
  outlined: false
}
