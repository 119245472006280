import * as RadixDialog from '@radix-ui/react-dialog'

import { Icon } from '../icon/Icon'

import { Content, Overlay } from './styles'

type DialogProps = {
  content: React.ReactNode
  open: boolean
  onChange: () => void
}

export const OptionsDialog: React.FC<DialogProps> = ({
  content,
  open,
  onChange
}) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onChange}>
      <RadixDialog.Portal>
        <Overlay>
          <Content>
            <div>{content}</div>
            <RadixDialog.Close asChild>
              <button
                aria-label="Close"
                className="absolute top-[10px] right-[10px]"
              >
                <Icon
                  type="outlined"
                  name="XIcon"
                  className="text-black  inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
                />
              </button>
            </RadixDialog.Close>
          </Content>
        </Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}
