import { Sidebar } from '@brmediagroup/mis-ui-library'
import { useContext, useMemo } from 'react'

import { NAVIGATION_ITEMS } from './constants'
import { NavigationMenuProps } from './protocols'

export const usePresenter = ({ pathname, onSelect }: NavigationMenuProps) => {
  const { toggleSidebar } = useContext(Sidebar.Context)

  const activeItem = useMemo(() => {
    return NAVIGATION_ITEMS.find((i) => pathname.includes(i.name))?.name ?? ''
  }, [pathname])

  const handleSelectItem = (value: string) => {
    onSelect(NAVIGATION_ITEMS.find((i) => i.name === value)?.name ?? '')
    toggleSidebar()
  }

  return { activeItem, handleSelectItem }
}
