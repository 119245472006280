import {
  Avatar,
  DropdownMenu as DropdownMenuUI
} from '@brmediagroup/mis-ui-library'
import React from 'react'
Icon

import { Icon } from '@/presentation/components/ui'

import { usePresenter } from './presenter'

type DropdownMenuProps = {
  iconUrl?: string
  brand?: string
  email?: string
}

export const BrandDropdownMenu: React.FC<DropdownMenuProps> = ({
  iconUrl,
  brand,
  email
}: DropdownMenuProps) => {
  const { handleSignOut, handleGoToSettings } = usePresenter()

  return (
    <div className="absolute bottom-0 w-full">
      <div className="p-6">
        <DropdownMenuUI>
          <DropdownMenuUI.Trigger asChild className="cursor-pointer">
            <div className="flex">
              <Avatar src={iconUrl} size="md" />
              <label className="text-slate-300 hover:text-slate-100 text-sm p-2 self-center cursor-pointer">
                {brand}
              </label>
              <Icon
                type="solid"
                name="ChevronUpIcon"
                className="absolute right-6 h-5 w-5 text-white self-center"
              />
            </div>
          </DropdownMenuUI.Trigger>
          <DropdownMenuUI.Content>
            <DropdownMenuUI.Arrow />
            <DropdownMenuUI.Item heading>{email}</DropdownMenuUI.Item>
            <DropdownMenuUI.Item onClick={handleGoToSettings}>
              <div className="flex gap-2 items-center">
                <Icon
                  type="solid"
                  name="CogIcon"
                  className="h-4 w-4 text-slate-500"
                />
                Configurações
              </div>
            </DropdownMenuUI.Item>
            <DropdownMenuUI.Item onClick={handleSignOut}>
              <div className="flex gap-2 items-center">
                <Icon
                  type="solid"
                  name="ArrowLeftIcon"
                  className="h-4 w-4 text-slate-500"
                />
                Sair
              </div>
            </DropdownMenuUI.Item>
          </DropdownMenuUI.Content>
        </DropdownMenuUI>
      </div>
    </div>
  )
}
