import * as RadixDialog from '@radix-ui/react-dialog'
import React, { useState } from 'react'

import { Icon } from '../icon/Icon'

import { MediaContent, Overlay } from './styles'

type MediaPreviewDialog = {
  content: React.ReactNode
  trigger: React.ReactNode
}

export const MediaPreviewDialog: React.FC<MediaPreviewDialog> = ({
  trigger,
  content
}) => {
  const [open, setOpen] = useState(false)

  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen}>
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <RadixDialog.Portal>
        <Overlay>
          <MediaContent>
            <div>{content}</div>
            <RadixDialog.Close asChild>
              <button aria-label="Close">
                <Icon
                  type="outlined"
                  name="XIcon"
                  className="text-black absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
                />
              </button>
            </RadixDialog.Close>
          </MediaContent>
        </Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
}
