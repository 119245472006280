import React, { useMemo } from 'react'

import { Providers } from '@/presentation/protocols'

import { SVG_ICONS } from './icons'

type Props = {
  provider: Providers
  className?: string
}

export const IconByProvider: React.FC<Props> = (props: Props) => {
  const Content = useMemo(
    () => SVG_ICONS[props.provider] ?? SVG_ICONS.none,
    [props.provider]
  )

  return (
    <div className={`icon-by-provider-container ${props.className ?? ''}`}>
      <Content />
    </div>
  )
}
