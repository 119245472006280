import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

const LoadingWrapper = styled('div', {
  variants: {
    withBackground: {
      true: tw`bg-black/30 w-full h-full absolute z-50 flex items-center pb-8 justify-center`
    }
  }
})

const Badge = styled('div', {
  ...tw`text-center flex items-center rounded-xl py-2 px-4`,
  variants: {
    withBackground: {
      true: tw`bg-white/10`,
      false: tw`bg-black/10`
    }
  }
})

export const Loading: React.FC<{
  loading?: boolean
  withBackground?: boolean
  wrapperClasses?: string
}> = ({ withBackground = false, loading = false, wrapperClasses }) => {
  if (!loading) return null

  return (
    <LoadingWrapper withBackground={withBackground} className={wrapperClasses}>
      <Badge withBackground={withBackground}>
        <div className="h-3 w-3 bg-purple-600 animate-ping rounded-full mr-4" />
        <p className="text-gray-500 text-sm">Carregando...</p>
      </Badge>
    </LoadingWrapper>
  )
}
