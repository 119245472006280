import React from 'react'
import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

const Svg = styled('svg', {
  ...tw`w-full`,
  variants: {
    colorType: {
      white: tw`fill-white`,
      purple: tw`fill-purple-600 `
    }
  }
})
Svg.defaultProps = {
  height: 32,
  colorType: 'purple'
}

type LogoProps = {
  containerClassName?: string
  svgProps: React.ComponentProps<typeof Svg>
}

export const Logo: React.FC<LogoProps> = ({ svgProps, containerClassName }) => {
  return (
    <div className={containerClassName}>
      <Svg viewBox="0 0 941 332" {...svgProps}>
        <path d="M348.52 0.109985C290.48 0.109985 243.42 47.16 243.42 105.21C243.42 105.77 243.42 112.58 243.42 121.8C243.42 143.05 243.42 177.11 243.42 177.11V215.83C243.42 224.99 235.99 232.42 226.83 232.42C217.67 232.42 210.24 224.99 210.24 215.83V177.11C210.24 177.11 210.24 143.04 210.24 121.8C210.24 112.58 210.24 105.77 210.24 105.21C210.24 47.17 163.19 0.109985 105.14 0.109985C47.09 0.109985 0.0400391 47.16 0.0400391 105.21C0.0400391 107.07 0.0400391 332 0.0400391 332H88.54V116.27C88.54 107.11 95.97 99.68 105.13 99.68C114.29 99.68 121.72 107.11 121.72 116.27V121.8V177.11C121.72 201.97 121.72 226.01 121.72 226.89C121.72 284.93 168.77 331.99 226.82 331.99C284.87 331.99 331.92 284.94 331.92 226.89C331.92 226 331.92 201.97 331.92 177.11V121.8V116.27C331.92 107.11 339.35 99.68 348.51 99.68C357.67 99.68 365.1 107.11 365.1 116.27V332H453.6C453.6 332 453.6 107.07 453.6 105.21C453.62 47.16 406.56 0.109985 348.52 0.109985Z" />
        <path d="M575.3 0.109985H486.8V332H575.3V0.109985Z" />
        <path d="M724.66 88.61H940.39V0.109985C940.39 0.109985 715.46 0.109985 713.6 0.109985C655.56 0.109985 608.5 47.16 608.5 105.21C608.5 163.26 655.55 210.31 713.6 210.31C714.16 210.31 720.97 210.31 730.19 210.31C751.44 210.31 785.5 210.31 785.5 210.31H824.22C833.38 210.31 840.81 217.74 840.81 226.9C840.81 236.06 833.38 243.49 824.22 243.49H730.18H608.5V332H730.19C730.19 332 833.43 332 835.29 332C893.33 332 940.39 284.95 940.39 226.9C940.39 168.85 893.34 121.8 835.29 121.8C834.4 121.8 810.37 121.8 785.51 121.8H730.2H724.67C715.51 121.8 708.08 114.37 708.08 105.21C708.06 96.04 715.49 88.61 724.66 88.61Z" />
      </Svg>
    </div>
  )
}
