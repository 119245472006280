import tw from 'twin.macro'

import { styled } from '@/main/config/stitches.config'

import { Loading } from '../loading/loading'

export const CardWrapper = styled('div', {
  ...tw`w-full flex justify-between items-center
    shadow-md rounded-md bg-white p-6`,
  variants: {
    canSelect: {
      true: tw`hover:shadow cursor-pointer transition-all
        ease-in duration-200`
    }
  }
})

CardWrapper.defaultProps = {
  canSelect: false
}

type CardProps = {
  canSelect?: boolean
  loading?: boolean
  children: React.ReactElement | React.ReactNode
}

export const Card: React.FC<CardProps> = ({
  loading = false,
  canSelect = false,
  children
}) => {
  return (
    <div className="relative">
      <Loading loading={loading} wrapperClasses="rounded-md" withBackground />
      <CardWrapper canSelect={canSelect}>{children}</CardWrapper>
    </div>
  )
}
